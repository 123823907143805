<script>

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Image
  } from 'sveltestrap';

  let size;
  let fullscreen;

  let open120 = false;
  let fullscreen120 = false;;
  let openScrollable120 = false;
  

  let open121 = false;
  let fullscreen121 = false;

  let open122 = false;
  let fullscreen122 = false;

  let open123 = false;
  let fullscreen123 = false;

  let open124 = false;
  let fullscreen124 = false;


  let open125 = false;
  let fullscreen125 = false;


  let open126 = false;
  let fullscreen126 = false;

  // const toggle110 = () => {
  //   fullscreen110 = true;
  //   open110 = !open110;
  // };

  const toggleScrollable120 = () =>
  {openScrollable120 = !openScrollable120; 
    fullscreen120 = true
    size = 'lg'
  };

  const toggle121 = () => {
    size = 'lg';
    open121 = !open121;
  };

  const toggle122 = () => {
    size = 'lg';
    open122 = !open122;
  };

  const toggle123 = () => {
    size = 'lg';
    open123 = !open123;
  };

   const toggle124 = () => {
    size = 'lg';
    open124 = !open124;
  };


   const toggle125 = () => {
    size = 'lg';
    open125 = !open125;
  };


   const toggle126 = () => {
    size = 'lg';
    open126 = !open126;
  };

 
</script> 


<div id="vitrine2"> <Button color="primary" on:click={toggleScrollable120}>Haus und Hof</Button></div>


<Modal isOpen={openScrollable120} toggle120={toggleScrollable120} scrollable {size}>
    <ModalHeader class="bg-primary text-light" toggle120={toggleScrollable120}>Haus und Hof</ModalHeader>
    <ModalBody class="bg-light justifyText">
    
      <p> Über Jahrhunderte hinweg wurde der gesamte Lebensraum des Menschen mitgeprägt und mitgestaltet von Symbolen, Zeichen, Bildern und Objekten die dem Volksglauben zugerechnet werden. Haus und Hof stellten dabei keine Ausnahme dar. Die sinnbildliche Ausgestaltung und volkskünstlerische Durchdingung des Raumes erfüllte ästhetische wie religiöse oder abergläubische Bedürfnisse. Die im Haus lebenden Personen sollten wie der Heimstatt an sich insbesondere vor Gefahren wie Krieg, Krankheit und Unwetter bewahrt werden.</p>
      <Image class="center" fluid alt="engelbilder" src="/images/120.1.jpg" />
      <p>Das Heilige wurde im Lebensraum, sei es auf weiter Flur oder am Dorfplatz durch unterschiedlichste Zeichen, Bilder und andere Hilfsmittel vergegenwärtigt. Insbesondere der Wohnraum, stellt für diese Vergegenwärtigung ein Untersuchungsfeld dar, in dem sich volksfromme Traditionen mit abergläubischen Praktiken vermengen. Gerade auch deshalb, da der Drang des Menschen, seine unmittelbare Umgebung mit visuellen Markern zu versehen, die einerseits das Bedürfnis nach Schutz und Heil artikulieren, andererseits eine dekorative Funktion erfüllen im Wohnraum sehr offensichtlich zu Tage tritt. Der sogenannte Herrgottswinkel, der auch heute noch in vielen Häusern Bayerns zu finden ist, kleine Hausaltäre oder aber die Verzierung von Gegenständen des alltäglichen Gebrauches, wie Besteck oder Möbeln, geben einen Eindruck von der dichten volksfrommen Durchdringung des Raumes. In dieser Hinsicht waren die als wirksam angesehen Mittel prominenter platziert, als es beispielsweise bei den weiter unten behandelten –oft in abergläubischer Weise benutzten – Coronazetteln der Fall gewesen ist. Mitunter wurde die Funktion der volksfrommen Andachtsmittel nur dann als religiös gedeutet, wenn das jeweilige Objekt als solches genutzt wurde. Der Gesamteindruck einer niederbayerischen Wohnstube um 1900 verwies mit seiner Vielzahl an Ausgestaltungsmitteln deutlich darauf, dass die Bewohner eindeutig in einer Tradition standen, die aufs Engste mit ihrer konfessionellen Identität verknüpft war .</p>  </ModalBody>
    
  <ModalFooter>
      <Button color="primary" on:click={toggleScrollable120}>schließen</Button>
     
    </ModalFooter>
  </Modal>




<div class ="row g-0">
<Card class="col-sm-4 p-2">
 
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="eingericht" src="/images/121.jpg" />
      </div>
      <h3 style="color: black">Eingericht</h3>
    <div>

   
    <Button color="primary" on:click={toggle121}>lesen</Button>
  

  <Modal isOpen={open121} {toggle121} {size}>
    <ModalHeader class="bg-primary text-light" {toggle121}>Eingericht</ModalHeader>
    <ModalBody class="bg-light justifyText">
<p>Das gezeigte Eingericht ist ein Beispiel daf&uuml;r, wie sich Arbeit, Andacht und Dekoration auf dem Gebiet der Volksfr&ouml;mmigkeit verbinden konnten. Eingericht ist ein Sammelbegriff f&uuml;r die modellhafte Darstellung einer religi&ouml;sen Alltagsszene, die in einer Flasche oder unter einer Glaskuppel montiert ist. Besonders im Alpenland begann sich diese Kunstform seit etwa der Mitte des 17. Jahrhunderts durchzusetzen, verbreitete sich von dort aus rasch im ganzen bayerischen Raum. Die Eingerichte bieten Raum f&uuml;r die spielerische Bildproduktion, da sie in den allermeisten F&auml;llen in Heimarbeit gefertigt wurden, wobei die Kunstfertigkeit von den zur Verf&uuml;gung stehenden Materialien, besonders aber auch von den F&auml;higkeiten des Herstellers abhing. Eingerichte finden sich demnach nicht nur in Flaschen oder Glaskuppeln, sondern k&ouml;nnen beispielsweise auch N&uuml;sse oder Schneckenh&auml;user als H&uuml;lle f&uuml;r die dargestellte Szene dienen. Dabei wurde freilich auch versucht die Szenen so klein wie m&ouml;glich abzubilden. Die Herstellung eines Eingerichts erfordert ein hohes Ma&szlig; an Geduld und Konzentration und konnte mitunter selbst als Andachtsform gelten. Damit wurde allerdings nicht nur eine in die Volksfr&ouml;mmigkeit eingebundene Arbeit verrichtet, auch kommt die Produktion von Eingerichten dem menschlichen Bed&uuml;rfnis der &bdquo;Anh&auml;ufung von Einzelst&uuml;cken&ldquo; entgegen. Eingerichte die nicht f&uuml;r die Ausstattung des eigenen Wohnraums benutzt wurden, wurden auch verkauft und brachten einen kleinen finanziellen Zugewinn.<br />
Die Motive der dargestellten Szenen sind vielf&auml;ltig. Von nicht-religi&ouml;sen Alltagszenen, wie der Arbeit im Bergbau oder auf dem Feld bis hin zu unterschiedlichen Darstellungen aus dem Leben Jesu Christ (in der Krippe, bei der Hochzeit von Kanaan, im Garten Gethsemane etc.) kann sich vieles im Eingericht finden. Die ausgestellte &bdquo;Geduldsflasche&ldquo; &ndash; wie Eingerichte auch genannt werden &ndash; vereint mehrere Episoden aus dem Neuen Testament: Vor dem papiernen Jesuskind in der Krippe steht ein ebenfalls aus Papier gefertigter kindlicher Johannes der T&auml;ufer, der auf den Gekreuzigten deutet. Unter der Krippe kann man ein Lamm erkennen, welches sinnbildlich f&uuml;r Christus &ndash; das Lamm Gottes &ndash; steht. Das Kreuz selbst tr&auml;gt auf der R&uuml;ckseite des L&auml;ngs- und Querbalkens die Inschrift &bdquo;Feld &ndash; 1915 &ndash; Zug. Meier Wolf&ldquo;. Der im Feld k&auml;mpfende Verwandte wurde wohl beim Betrachten des Eingerichts besonders ins Gebet miteingeschlossen und es kann davon ausgegangen werden, dass die Geduldsflasche anl&auml;sslich seines Einsatzes gefertigt wurde. Sie diente damit einerseits der Andacht f&uuml;r den Verwandten, vergegenw&auml;rtigte ihn inmitten der Wohnstube, erf&uuml;llte aber andererseits auch ihre Rolle als dekoratives Element. Die Intention besonderen Schutz f&uuml;r den Soldaten zu erflehen wird &uuml;berdies daran deutlich, dass die sogenannten Waffen Christi in der Geduldsflasche Platz gefunden haben und das Kreuz damit zum Arma-Christi Kreuz wurde. Seit dem 14. Jahrhundert ist die Vorstellung belegt, dass die Andacht vor einem Arma-Christi Kreuz vor dem unvorhergesehenen Tode bewahren sollte. Vor dem Hintergrund, dass der in das Kreuz Eingeschriebene im Krieg k&auml;mpfte, scheint die Motivwahl daher umso plausibler.</p>
    </ModalBody >
    <ModalFooter>
      <Button color="primary" on:click={toggle121}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-4 p-2">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="waffen_christi_kreuz" src="/images/122.jpg" />
      </div>

      <h3 style="color: black">Waffen Christi Kreuz</h3>
    <div>

   
    <Button color="primary" on:click={toggle122}>lesen</Button>
  

  <Modal isOpen={open122} {toggle122} {size}>
    <ModalHeader class="bg-primary text-light" {toggle122}>Waffen Christi Kreuz</ModalHeader>
    <ModalBody class="bg-light justifyText">
<p>Die Waffen Christi finden sich nicht nur in Eingerichten, sondern auf unz&auml;hligen Objekten der religi&ouml;sen Volkskunde, wie beispielsweise Gehst&ouml;cken, Sterbekreuzen oder Medaillen. Auch als Einzelobjekte kommen sie vor, wobei sich ihre Erscheinungsform von gro&szlig;en Flurkreuzen bis hin zu kleineren Hauskreuzen erstrecken kann. Bereits seit dem Hochmittelalter war das Motiv der Waffen Christi verbreitet. Im 19. Jahrhundert war es fester Bestandteil der volkst&uuml;mlichen Bilder- und Zeichenwelt. Kleinere Andachtskreuze bzw. Hauskreuze waren beliebte Ausdrucksformen der Passionsfr&ouml;mmigkeit in Wohnstuben, erf&uuml;llten aber gleichzeitig einen &auml;sthetischen Zweck. Die Andacht vor den Marterinstrumenten sollte das leidenschaftliche Mitgef&uuml;hl f&uuml;r den geschundenen Christus mobilisieren. Kurat Frank schreibt Anfang des 20. Jahrhunderts &uuml;ber die Waffen Christi:</p>



<p><em>Dieselben scheinen auch ohne Christus-Bild an Kreuzen in der Flur, Kirche, Wohnung. Auch hier lassen sich interessante Studien anstellen, indem man die einzelnen Gegenst&auml;nde notiert. Der fromme Eifer der Vorzeit konnte n&auml;mlich nicht genug Zeichen des Leidens Christi finden und bis in die letzte Zeit gab es &bdquo;B&auml;stler&ldquo;, die solche Leidenswerkzeuge an Winter-Abenden schitzten und ein Kreuz mit ihnen errichteten; der Sturm hat manche derselben umgeworfen &ndash; und sie sind nicht mehr erneuert worden, leider! Die Bauern haben ja an Winterabenden jetzt so viel Gescheiteres zu tun und eine verst&auml;ndnisvolle Anregung fehlt zu allermeist</em>.</p>



<p>Unter den &bdquo;Zeichen des Leidens Christi&ldquo; finden sich beim hier gezeigten Arma-Christi Kreuz folgende:</p>


  <Image class="center" fluid alt="waffen_christi_kreuz" src="/images/122.jpg" />

<ul>
	<li>Titulus Crucis (INRI)</li>
	<li>2 x Salbenb&uuml;chse (mit denen Christus nach der Kreuzabnahme gesalbt wurde)</li>
	<li>Zange (mit der die N&auml;gel aus dem Kreuz gezogen wurden)</li>
	<li>Hand (die Hand die Christo ins Gesicht schlug)</li>
	<li>Hacke</li>
	<li>Schwei&szlig;tuch der Veronika</li>
	<li>Hammer (mit dem die N&auml;gel eingeschlagen wurden)</li>
	<li>Beutel mit den 30 Silberlingen</li>
	<li>Laterne (die bei der Gefangennahme Christi getragen wurde)</li>
	<li>Heilige Eucharistie</li>
	<li>2x Kreuzn&auml;gel</li>
	<li>Dornenkrone</li>
	<li>Gef&auml;&szlig;e f&uuml;r Galle und Essig</li>
	<li>Spottfeige</li>
	<li>Stab mit Essigschwamm</li>
	<li>Lanze (mit der die Seite Christi ge&ouml;ffnet wurde)</li>
	<li>Durchbohrte Hand Christi</li>
	<li>Nahtloses Gewand Christi</li>
	<li>Leiter (f&uuml;r die Kreuzabnahme)</li>
	<li>W&uuml;rfel (mit denen um das Gewand Christi gelost wurde)</li>
	<li>2x Rutenb&uuml;ndel (Gei&szlig;elung)</li>
	<li>2x Purpurner Mantel (den Christus bei der Verspottung trug)</li>
	<li>S&auml;ge</li>
	<li>Durchbohrte F&uuml;&szlig;e Christi</li>
	<li>Herz Jesu</li>
	<li>Messer (zum Teilen der Kleider)</li>
	<li>2x Fackel (die bei der Gefangennahme getragen wurden)</li>
	<li>Gei&szlig;lungss&auml;ule</li>
	<li>Pickel (zum Ausheben der Kreuzesgrube)</li>
	<li>Maria unter dem Kreuz</li>
</ul>



<p>Wahrscheinlich befand sich &ndash; &auml;hnlich wie beim Waffen-Christi Kreuz im ausgestellten Eingericht &ndash; am Kopf des Kreuzes ein Hahn, der auf die Verleugnung des Petrus hinweisen soll.<br />
Das gezeigte Arma-Christi Hauskreuz, vereint also zahlreiche Leidensinstrumente, wie sie in den Evangelien beschrieben werden, f&uuml;gt allerdings auch Bilder hinzu, die nicht direkt f&uuml;r die Marter Christi benutzt wurden oder nur sinnbildhaft in einer engeren Beziehung mit der Kreuzigung stehen. Lediglich ein Beispiel soll im Folgenden n&auml;her beleuchtet werden.<br />
Die dargestellte Spottfeige verweist auf eines der sogenannten Geheimen Leiden Christi. Als Geheime Leiden Christi werden diejenigen Leiden Jesu bezeichnet, welche nicht in der neutestamentlichen &Uuml;berlieferung enthalten sind und auf Grundlage von Privatoffenbarungen Einzug in die Volksfr&ouml;mmigkeit gefunden haben. Seit dem 18. Jahrhundert wurden diese Geheimen Leiden beispielsweise &uuml;ber eigens angefertigte Heftchen verbreitet und man nahm an, dass eine Andacht zu den Geheimen Leiden besonders wirksam f&uuml;r die Vergebung der S&uuml;nden sei. Unter die Leiden z&auml;hlen heutzutage merkw&uuml;rdig erscheinende Gr&auml;ueltaten, wie etwa das Durchbohren der Zunge Christi. Beliebt war auch das Motiv &bdquo;Christus&ldquo; im Kerker. Mit der Verehrung der Geheimen Leiden wurden antisemitische Bilder produziert, da die Folterungen insbesondere den Juden zugeschrieben wurden. In diesen Kontext ist auch die auf dem Waffen Christi Kreuz angebrachte Spottfeige einzuordnen: Laut einer Privatoffenbarung der Kreszentia von Kaufbeuren sei bei der Kreuzschleppung ein Jude an Christus herangetreten, der das Kreuz anhob als wollte er beim Tragen helfen. Pl&ouml;tzlich h&auml;tte er es fallen gelassen, so dass die Kante des Kreuzes eine gro&szlig;e Wunde in die Schulter Christi schlug. Danach habe der Jude Christus in verh&ouml;hnender Weise die Spottfeige gezeigt. Die Spottgeb&auml;rde der Feige ist auf Passionsdarstellungen, beispielsweise, bei der Verspottung Christi im Hause des Kaiaphas, nach der Dornenkr&ouml;nung und bei der Kreuzigung seit dem fr&uuml;hen 15. Jahrhundert, immer wieder dargestellt. Von hier aus gelangt die Versinnbildlichung der Geb&auml;rde zu den Waffen Christi, die bei Passionsprozessionen mitgetragen und an Rosenkr&auml;nze geh&auml;ngt wurde.</p>

<Image class="center" fluid alt="waffen_christi_kreuz" src="/images/122.2.jpg" />

<Image class="center" fluid alt="waffen_christi_kreuz" src="/images/122.3.jpg" />


    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle122}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-4 p-2 ">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="skull" src="/images/123.jpg" />
      </div>
      <h3 style="color: black">Wettersegen</h3>
    <div>

   
    <Button color="primary" on:click={toggle123}>lesen</Button>
  

  <Modal isOpen={open123} {toggle123} {size}>
    <ModalHeader class="bg-primary text-light" {toggle123}>Wettersegen</ModalHeader>
    <ModalBody class="bg-light justifyText">

<p>Eine existentielle Gefahr f&uuml;r Wohlstand und Leben stellt der Einfluss des Wetters dar. Gerade in einer agrarisch gepr&auml;gten Gesellschaft, deren Auskommen vom Ertrag der Ernte abhing, war das Wetter ein Faktor, der best&auml;ndig mitbedacht werden musste. Der Wunsch danach, Einfluss auf das Wetter zu nehmen, ist demnach ein Ph&auml;nomen, das sich &uuml;ber Jahrtausende hinweg in wahrscheinlich allen Kulturen feststellen l&auml;sst. So verwundert es nicht, dass sich bereits fr&uuml;h christliche Formen entwickelten, die zwar &ndash; gem&auml;&szlig; des Glaubenskonzeptes &ndash; das Wetter nicht auf zauberische Weise beeinflussen, aber doch durch bestimmte Segensformeln zumindest gedeihliches Wetter erbitten oder Unwetter abhalten wollten. So finden sich bereits seit der Sp&auml;tantike besondere Messformulare, die beispielsweise die Bitte nach gem&auml;&szlig;igten und fruchtbaren Temperaturen, gute Witterung und Fruchtbarkeit der Erde oder reichen und fruchtbaren Regen beinhalten. Abgesehen aber von den eigens f&uuml;r (bzw. gegen) das Wetter gelesenen Messen, existiert die noch heute praktizierte Form des liturgischen Wettersegens.<br />
Freilich waren auch diese Praktiken nicht vor von der Kirche als Aberglauben verurteilten Praktiken gefeit. Entsprechend der antiken &Uuml;berlieferung des Analogie-Regenzaubers, wurden an manchen Orten Heiligenbilder oder Reliquien mit Wasser &uuml;bergossen oder untergetaucht, da man sich davon reichen Regen erhoffte. Nicht nur das &bdquo;einfach Volk&ldquo; vollzog derartige Praktiken, mancherorts war es &uuml;blich, dass auch kirchliche W&uuml;rdentr&auml;ger mit viel Pomp an den Wetterzeremonien beteiligt waren, obwohl sie von der Kirche verboten worden waren. Insbesondere in Bayern waren diese Br&auml;uche so stark verbreitet, dass nicht nur die geistliche, sondern auch die weltliche Macht eingreifen musste: So erlie&szlig; beispielsweise Herzog Maximilian I. von Bayern (1597&ndash;1651) ein Verbot bez&uuml;glich des Untertauchens von Heiligenbildern um Regen zu erlangen.<br />
Die Br&auml;uche galten als Aberglauben und Entehrung des Heiligen und insbesondere die Formenvielfalt in Bezug auf derartige Wettersegen &ouml;ffnete Neuinterpretationen oder Abwandlungen T&uuml;r und Tor. Aus diesem Grund war die Kirche auch seit jeher bem&uuml;ht, Regelungen daf&uuml;r zu finden in welcher Form diese Segen vollzogen werden sollten und wer das Recht hatte die Gebete, die auch Exorzismen beinhalten konnten, zu sprechen. Der gesprochene Wettersegen wurde im Laufe der Zeit demnach mehr und mehr von Klerikern &uuml;bernommen, wohingegen die Laien Alternativen fanden, die dann weniger darin bestanden f&uuml;r gedeihliches Wetter zu beten, als vielmehr darin, Gewitter vom eigenen Heim fernzuhalten. Das Gewitter, gefahrenvoll f&uuml;r Mensch, Vieh, Heim und Ernte, war schon allein ob seines in vormoderner Zeit unerkl&auml;rlichen Zustandekommens, stets Gegenstand, der Sorge, der Sage und des Nachdenkens. Eng verbunden damit ist aber auch die christliche Vorstellung, dass Gott es zulasse, dass die D&auml;monen die Kr&auml;fte der Natur gebrauchen d&uuml;rften um dem Menschen zu schaden. Als Sitz der b&ouml;sen Geister galt aufgrund der paulinischen Ausf&uuml;hrungen im Epheserbrief der Luftkreis, von wo aus auch die Gewitter ihren Ausgang nehmen w&uuml;rden. Blitz und Donner wurden ebenso als d&auml;monische Einwirkung, wie auch als Strafhandlung Gottes verstanden. Der Historiker Adolf Franz gibt in seinem monumentalen Werk <em>Die kirchlichen Benediktionen im Mittelalter</em> eine Reihe von Beispielen:</p>



<p><em>So soll einst in Sachsen ein Blitzstrahl unter die Zuschauer einer theatralischen Auff&uuml;hrung gefahren sein und 20 Menschen get&ouml;tet haben. Im Bistum Trier habe der Blitz einen s&uuml;ndhaften Priester, der mit dem K&uuml;ster die Glocken l&auml;utete, get&ouml;tet, w&auml;hrend D&auml;monen in der Kirche Unfug trieben. Einem Ritter, der bei dem Ausbruch eines Gewitters ausgerufen habe: &bdquo;Siehe, nun kommt der Teufel wieder herauf&ldquo;, sei sein S&ouml;hnchen vom Blitze erschlagen und sein Geh&ouml;ft verw&uuml;stet worden. Solche viel verbreitete Erz&auml;hlungen mu&szlig;ten nat&uuml;rlich den Glauben an die Macht der D&auml;monen befestigen und die Furcht vor Blitz und Donner vermehren.</em></p>



<p>Diese Furch hielt sich &uuml;ber die Jahrhunderte hinweg, wobei nicht immer gleich der eigene Sohn vom Blitz bedroht werden musste, sondern insbesondere die Angst um Haus und Hof &ndash; die ja die Lebensgrundlage der Bev&ouml;lkerung darstellten &ndash; im Vordergrund der volksfrommen Ausdrucksweisen stand. Umso dankbarer war man, wenn die Heimstatt trotz Blitzeinschlag vor Verw&uuml;stung bewahrt wurde. Ein Votivbild aus dem Jahre 1889 berichtet beispielsweise, dass w&auml;hrend des Rosenkranzgebetes der Blitz auf dem Anwesen eines gewissen Leopold Schwinghamer bei Johannesbrunn (zwischen Dingolfing und Vilsbiburg) eingeschlagen sei &bdquo;ohne jedoch zu z&uuml;nden&ldquo;.</p>

<Image class="center" fluid alt="skull" src="/images/120.1.jpg" />

<p>Mit Blick auf die dem Volksglauben entspringenden Hilfsmittel, ist es vor dem Hintergrund der aus Antike und Mittelalter gr&uuml;ndenden Erkl&auml;rungen zum Zustandekommen von Gewittern nur logisch, dass Gegenst&auml;nde, die gegen Unwetter helfen sollten, gleichzeitig auch eine d&auml;monenabwehrende Wirkung besitzen mussten. Ein herausragendes Beispiel f&uuml;r eine solches Hilfsmittel stellt der gezeigte Wettersegen dar. <Image fluid class="center" alt="wettersegen" src="/images/123.jpg" /> <br>Im Gegensatz zum gesprochenen Wettersegen, ist dieser ein Konglomerat aus unterschiedlichen gesegneten Dingen, wobei &uuml;berdies auch pflanzliche &ndash; bzw. aus der Natur stammende &ndash; Stoffe verarbeitet wurden. Anders als die sp&auml;ter noch behandelten Breverln, stellte der Wettersegen einen Teil seines Inhalts deutlich zur Schau. Im gezeigten Exemplar befinden sich unter anderem:</p>



<ul>
	<li>Wachsabdruck (Agnus Dei)</li>
	<li>Plaketten mit unterschiedlichen Heiligen (z.B. Florian, Evangelisten, Hubertus, Franziskus)</li>
	<li>Verschiedene Kreuze ( z.B. Benediktuskreuz, Scheyrer Kreuz)</li>
	<li>Tonfiguren (Nikolaus(?), Christus, Nepomuk)</li>
	<li>Sebastianipfeil</li>
	<li>Nepomukzunge</li>
	<li>Kissenamulette</li>
	<li>Roter Stoff (geisterabwehrend)</li>
	<li>Wachsmedaillons mit den Namen Jesu und Mariens</li>
	<li>Verschiedene Samen und andere Naturprodukte</li>
</ul>



<p>Bei der &Ouml;ffnung des holzgefassten Rahmens, konnte eine weitere Entdeckung gemacht werden: Verdeckt von mehreren Lagen kreisförmigen weißen und blauen Papieres, befindet sich auf der Rückseite der Installation ein auf Pappe geklebter bedruckter Zettel, auf dem zwei weitere kleine Kissenamulette angebracht sind. Im Zentrum des Zettels befindet sich ein viergeteiltes Kreisfeld, das in senkrechter Richtung durch einen Pfeil getrennt wird. Darin stehen die Namen Jesu und Mariens, weitere Umschreibungen f&uuml;r den Namen Gottes aus der j&uuml;dischen Tradition (<em>Adonai</em>) und die im Johannesevangelium vorkommende Schriftstelle <em>et verbum caro factum est </em>(und das Wort ist Fleisch geworden). Aufgeteilt auf jedes der vier Textfelder finden sich die Buchstaben [A] G L A. Dabei handelt es sich um ein sogenanntes Notarikon, ein Verfahren in der j&uuml;dischen Hermeneutik, bei dem jeder Anfangs- oder Schlussbuchstabe eines Wortes f&uuml;r ein anderes Wort gestellt wird, woraus sich neue W&ouml;rter oder S&auml;tze ergeben. Im Laufe des 14. Jahrhunderts erscheint A G L A auch in magischen Schriften und wurde im deutschen Sprachraum interpretiert als <em>A llm&auml;chtiger G ott L &ouml;sch A us</em>. Nicht ohne Grund findet sich diese Buchstabenreihenfolge also auf dem das Heim sch&uuml;tzenden Wettersegen, da mit dem Ausl&ouml;schen das L&ouml;schen von Feuer gemeint war.<br />
Im &auml;u&szlig;ersten Rand des Kreises finden sich die Buchstabenreihenfolgen INRI und MLMI. Darum herum gruppieren sich in konzentrischen Kreisen verschiedene Texte, darunter:</p>

<ul>
	<li>Ein Haussegen 
	<li>Ein Wettersegen gegen Gewitter und f&uuml;r fruchtbares Wetter</li>
	<li>Eine Anrufung Gottes unter vielen verschiedenen Namen (<em>Deus Deorum Deus, Agla Potentissimus Deus, Adonai Dominus Deus</em>, etc.)</li>
</ul>



<p>&bdquo;Viel hilft viel&ldquo; k&ouml;nnte eine Kurzbeschreibung derartiger Wettersegen lauten. Einerseits war es eben diese Anh&auml;ufung der geweihten Gegenst&auml;nde, die dem Volksglauben nach die Wirkung des gesamten Wettersegens sozusagen potenzieren sollte, andererseits das Verwenden von Naturstoffen, was aus kirchlicher Sicht kritisch gesehen wurde und das gesamte Konglomerat in die N&auml;he des Aberglaubens r&uuml;ckte. Auf textlicher Ebene kam freilich die Vermengung mit kabbalistischen Vorstellungen und das Anwenden von Formeln, die als Zauberspr&uuml;che galten, hinzu.</p>
<Image class="center" fluid alt="wettersegen_hinten" src="/images/123.2.jpg" />

    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle123}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-4 p-2 ">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="skull" src="/images/124.jpg" />
      </div>
      <h3 style="color: black">Feierabendziegel</h3>
    <div>

   
    <Button color="primary" on:click={toggle124}>lesen</Button>
  

  <Modal isOpen={open124} {toggle124} {size}>
    <ModalHeader class="bg-primary text-light" {toggle124}>Feierabendziegel</ModalHeader>
    <ModalBody class="bg-light justifyText">

      <p>Nicht nur im Inneren der Wohnung wurden Zeichen und Symbole angebracht, die f&uuml;r einen Schutz von Haus und Hof dienen sollten. Auch Stallt&uuml;ren, W&auml;nde oder eben das Dach wurden sozusagen gegen die Einfl&uuml;sse des Unwetters oder die das Unwetter verursachenden D&auml;monen gewappnet. Wenig bekannt aber sehr verbreitet ist in dieser Hinsicht das Anbringen von sogenannten Feierabendziegeln. Als &bdquo;Feierabendziegel&ldquo; werden handgefertigte Ziegel bezeichnet, die auf unterschiedliche Art und Weise verziert wurden. Oftmals wurden sie mit einem Christus- oder Marienmonogramm versehen, um zum Ausdruck zu bringen, dass das gesamte Haus unter den Schutz Christi gestellt werden soll. Insbesondere der Fakt, dass Feierabendziegel nach ihrer Anbringung auf dem Dach oftmals gar nicht mehr erkennbar waren, verdeutlicht, dass die Schutzfunktion mitunter als wichtiger erachtet wurde als die der symbolischen Kommunikation. Neben christlichen Symbolen waren zudem andere Zeichen wie Halb- und Viertelsonnen &ndash; auch Hexenbesen genannt &ndash;, oder Fruchtbarkeits- und Schutzsymbole beliebt. Einige Interpretationen gehen dahin, dass einerseits D&auml;monen, Geister und Hexen durch diese Symbole abgewehrt werden sollten, andererseits ein besonderer &bdquo;fruchtbarer&ldquo; Segen auf das Haus herabgerufen werden sollte.</p>

      <Image class="center" fluid alt="skull" src="/images/124.jpg" />
      <Image fluid alt="skull" src="/images/124.2.jpg" />
      <Image fluid alt="skull" src="/images/124.3.jpg" />

    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle124}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-4 p-2 ">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="skull" src="/images/125.jpg" />
      </div>
      <h3 style="color: black">Hufeisen</h3>
    <div>

   
    <Button color="primary" on:click={toggle125}>lesen</Button>
  

  <Modal isOpen={open125} {toggle125} {size}>
    <ModalHeader class="bg-primary text-light" {toggle125}>Hufeisen</ModalHeader>
    <ModalBody class="bg-light justifyText">
<p>Keine Ausstellung zum Thema Aberglauben ohne das Hufeisen. Es gilt wohl als eines der bekanntesten Ausdrucksmittel abergl&auml;ubischer Symbole in Bezug auf Haus und Hof. In vielen Regionen Europas wurden Hufeisen an H&auml;usern angebracht, um Gl&uuml;ck auf den Hausstand herabzurufen. Sowohl das Material Eisen (geisterabwehrend), als auch die Form des Hufeisens (C f&uuml;r Christus) spielten dabei eine Rolle. W&auml;hrend der fr&uuml;hen Neuzeit herrschte in einigen Regionen die Vorstellung vor, Hexen w&uuml;rden sich vor Pferden f&uuml;rchten, was die Verbreitung von Hufeisen als Schutzmittel belebte. Die Kirche ma&szlig; diesem weit verbreiteten Ph&auml;nomen keine wesentliche Bedeutung zu.</p>
<Image class="center" fluid alt="hufeisen" src="/images/125.jpg" />   

</ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle125}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>



<Card class="col-sm-4 p-2">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="skull" src="/images/126.jpg" />
      </div>
      <h3 style="color: black">Caravacakreuz</h3>
    <div>

   
    <Button color="primary" on:click={toggle126}>lesen</Button>
  

  <Modal isOpen={open126} {toggle126} {size}>
    <ModalHeader class="bg-primary text-light" {toggle123}>Caravacakreuz</ModalHeader>
    <ModalBody class="bg-light justifyText"> 
<p>Die Vielzahl von Kreuzesformen im Christentum f&uuml;hrte dazu, dass den unterschiedlichen Kreuzformen unterschiedliche &bdquo;Wirkungsbereiche&ldquo; zugesprochen wurden. Die Strahlkraft solcher Zuschreibungen konnte sich &uuml;ber ganz Europa und &uuml;ber lange Zeiten hinweg entfalten. Ein Beispiel f&uuml;r ein Kreuz, das unter anderem besonders Wirksam gegen Unwetter aller Art sein sollte, ist das Kreuz von Caravaca. Laut einer Legende soll der maurische Herrscher der spanischen Stadt Caravaca einen Priester gezwungen haben, eine Heilige Messe zu feiern. Alle Utensilien waren vorhanden, nur das Kreuz f&uuml;r den Altar fehlte. Daraufhin sollen Engel auf wundersame Weise ein Kreuz herbeigeschafft haben. Durch dieses Wunder tief beeindruckt, soll sich der muslimische Herrscher Abu&uacute; Zeid zum Christentum bekehrt haben. Die Stadt selbst aber, die in der Vergangenheit oftmals von Gewittern heimgesucht worden sein soll, blieb von diesem Tag an vor solchen gefeit. So war das Caravacakreuz zum Wetterkreuz geworden.<br />
  <Image class="center" fluid alt="caravacakreuz" src="/images/126.jpg" />   
Seit dem 16. Jahrhundert verbreiteten sich Nachbildungen dieses Kreuzes in ganz Europa. Nicht nur gegen Unwetter sollte es helfen, sondern unter anderem auch gegen die Cholera und andere Krankheiten. Am h&auml;ufigsten war es aber auf Hausd&auml;chern oder Turmspitzen anzutreffen, um Gewitter abzuhalten. Aber auch als Hauskreuz war es beliebt. Einen milit&auml;rischen Gebrauch fand es beispielsweise bei der Schlacht am Wei&szlig;en Berg 1620. Das Heer der katholischen Liga wurde vor der Schlacht mit einem Caravacakreuz gesegnet. In der Folgezeit wurden ihm viele Wunder zugesprochen und eine Andacht zum Kreuz von Caravaca war mit Abl&auml;ssen versehen.<br />
1678 verbot die Kongregation f&uuml;r Abl&auml;sse und die heiligen Reliquien unter Papst Urban VIII. die Verehrung des Caravacakreuzes, da sie an einigen Orten allzu magische Z&uuml;ge angenommen hatte. Auch das Herstellen von Caravacakreuzen als Amulette wurde untersagt.</p>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle126}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>









</div>

<style>



h3 {

		color: white;
	}

</style>







