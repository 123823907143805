<script>
  import { Container, Image } from 'sveltestrap';


  import { Fade, Button, Card, Icon } from 'sveltestrap';
  let isOpen = false;

 
</script>

    
<div id ="Raum2"> 
  <Image style="width:100%" fluid alt="fg3" src="/images/fg3.jpg"  />
<div fluid class="bg-dark text-white fluid p-3">

    <h2>Gegen (Über-)Irdisches</h2>

</div>




</div>





<Container  fluid class="bg-dark" mt-4 pt-4>


    
 <br>
 
 <p class="justifyText text-white">Die dem Katholizismus inh&auml;rente Bild- und Objektfreudigkeit findet ihren Ausdruck nicht nur im sakralen Raum, sondern auch im Alltag der Gl&auml;ubigen. Rosenkr&auml;nze, Amulette, Breverln oder Medaillen geben Einblick in die handwerklich-kunsthistorische Objektegeschichte, sie dokumentieren aber auch menschliche &Auml;ngste und N&ouml;te sowie die Strategien der Absicherung, die zu ihrer Bek&auml;mpfung angewandt wurden.<br />
Die katholische Lehre zu D&auml;monen ist komplex und wurde im Laufe der Geschichte immer wieder missverstanden oder &ndash;gedeutet. Obwohl die Katholische Kirche durchaus approbierte Mittel kennt, die auf die eine oder andere Art zum Schutz gegen D&auml;monen gebraucht werden k&ouml;nnen, erwuchsen aus der gelebten Volksfr&ouml;mmigkeit stets Vorstellungen, die entweder nicht mit der Lehre kongruent waren oder, Wirksamkeiten verschiedener Schutzmittel propagierten, die weit &uuml;ber die anerkannte Wirkung der sogenannten Sakramentalien der Kirche hinausgingen. Insbesondere der Schutz vor Geistern, Hexen oder dem Teufel spielte eine bestimmende Rolle im Themenbereich der Amulette, doch auch irdische Gefahren &ndash; eine feindliche Kugel, Krankheit oder Feuer &ndash; sollten mit diesen bek&auml;mpft werden. Bartholom&auml;us Spirkner bezeichnet die Besch&auml;ftigung mit diesem Graubereich als &bdquo;schl&uuml;pfrigen Boden&ldquo; auf dem man sich bewegen w&uuml;rde. Selbst der Kirchenmann k&ouml;nne zuweilen in seinem Urteil get&auml;uscht werden. Abgesehen von Breverln, Devotionalien, Rosenkr&auml;nzen oder modifizierten Alltagsgegenst&auml;nden, die eine apotrop&auml;ische &ndash; also geisterabwehrende &ndash; Wirkung besitzen sollten, bewegt sich auf diesem schl&uuml;pfrigen Boden auch das Gebet. In welcher Weise dieses zuweilen verzweckt wurde und damit nicht mehr Teil der kirchlichen Lehre war, aber auch, welche kirchlichen Hilfsmittel von den Gl&auml;ubigen uminterpretiert oder missgedeutet wurden oder aber &bdquo;Werkzeuge&ldquo; geschaffen, die g&auml;nzlich der Welt des Aberglaubens entspringen, wird in Raum <em>2 Gegen (&Uuml;ber-)Irdisches </em>veranschaulicht.</p>
<div class="video"><iframe width="560" height="315" src="https://www.youtube.com/embed/GXRv3azXaik" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>

<br>




</Container>