<script>
  import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
  } from 'sveltestrap';

  let isOpen = false;
  const toggle = () => (isOpen = !isOpen);
</script>
<div class="sticky-top" style="background-color: #d63384">
<Navbar  dark >
  <NavbarBrand href="/" class="me-auto"><h3 style="color:#ffffff">Museum Dingolfing</h3></NavbarBrand>
  <NavbarToggler  on:click={toggle} class="me-2" />
  <Collapse {isOpen} navbar>
    <Nav navbar>

      <NavItem>
        <NavLink href="#Home">Home</NavLink>
      </NavItem>

      <NavItem>
        <NavLink a href="#intro">Herzensgeheimnisse und düstere Schatten</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#Raum1">Von der Wiege bis zur Bahre</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#Raum2">Gegen (Über-)Irdisches</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#Raum3">Heil und Heilung</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#Raum4">Schreckgestalten und winterlicher Spuk</NavLink>
      </NavItem>
    </Nav>
  </Collapse>
</Navbar>
</div>