<script>

	import Navbar from "./Navbar.svelte";
	import Footer from "./Footer.svelte";
	import Hero from "./Hero.svelte";
	import Intro from "./Intro.svelte";
	import Raum1 from "./Raum1.svelte";
	import Vitrine1 from "./Vitrine1.svelte";
	import Vitrine2 from "./Vitrine2.svelte";
	import Vitrine3 from "./Vitrine3.svelte";
	import Vitrine4 from "./Vitrine4.svelte";
	import Raum2 from "./Raum2.svelte";
	import Raum2Objekte from "./Raum2Objekte.svelte";
	import Raum3 from "./Raum3.svelte";
	import Raum3Objekte from "./Raum3Objekte.svelte";
	import Raum4 from "./Raum4.svelte";
 

	
</script>
<main>
	<Navbar/>
	<Hero/>
	
	<Intro/>
	
	<Raum1/>

	<Vitrine1/>

	<Vitrine2/>

	<Vitrine3/>
	
	<Vitrine4/>

	<Raum2/>

	<Raum2Objekte/>

	<Raum3/>
	<Raum3Objekte/>
	<Raum4/>

	
	<Footer/>
</main>

<style>

		main {

		font-family: 'Libre Franklin', sans-serif;
		text-align: center;
		margin: 0 auto;
	}

	

	
</style>