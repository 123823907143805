<script>

import {
    
    Navbar, Icon
 
    } from 'sveltestrap';

</script>

<footer class="bg-primary text-center ">

  <div class="container p-4">


    
    <section class="mb-1">
      <div style="display: inline-block">
      <h1 style="display: inline-block">
        <a href="https://www.facebook.com/MuseumDingolfing/"><Icon class="text-white" name="facebook" /></a>
   
</h1> 

<h1 style="display: inline-block"><a href="https://www.instagram.com/museumdingolfing/?hl=bg"><Icon class="text-white" name="instagram" /></a></h1>

<h1 style="display: inline-block"><a href="https://www.youtube.com/channel/UCBsN4BYnxo4vlM_vWD3yQ6w"><Icon class="text-white" name="youtube" /></a></h1></div>

      <p style="color:white">
        Museum Dingolfing <br>
        Obere Stadt 19 <br>
        84130 Dingolfing <br>
        info@herzogsburg.de <br>
         <a href="https://www.museum-dingolfing.de">www.museum-dingolfing.de</a>
      </p>
    </section>
    
    
  </div>

 <div class="text-center text-light p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © 2021 <br>Idee/Umsetzung/Kuration: Thomas Kieslinger <br>
    <a class="text-light" href="https://philippbues.com/">Web Design: Philipp Bues</a>
    <img
      src="https://vg04.met.vgwort.de/na/ba6e1b1c498c4f1a955a80abbc7ae1c2"
      width="1"
      height="1"
      alt=""
    />
  </div>


</footer>
<style>

  h1 {

    padding: 5px;
  }
</style>
