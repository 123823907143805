<script>

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Image
  } from 'sveltestrap';

  let size;

  let open110 = false;
  let fullscreen110 = false;
    let openScrollable110 = false;
  

  let open111 = false;
  let fullscreen111 = false;

  let open112 = false;
  let fullscreen112 = false;



  let open113 = false;
  let fullscreen113 = false;

  // const toggle110 = () => {
  //   fullscreen110 = true;
  //   open110 = !open110;
  // };

  const toggleScrollable110 = () =>
  {openScrollable110 = !openScrollable110; 
    fullscreen110 = true
    size='lg'
  };

  const toggle111 = () => {
    size = 'lg';
    open111 = !open111;
  };

  const toggle112 = () => {
    size = 'lg';
    open112 = !open112;
  };

  const toggle113 = () => {
    size = 'lg';
    open113 = !open113;
  };

 
</script> 


<div id="vitrine1"> <Button color="primary" on:click={toggleScrollable110}>Schwangerschaft und Geburt</Button></div>


<Modal isOpen={openScrollable110} toggle110={toggleScrollable110} scrollable {size}>
    <ModalHeader class="bg-primary text-light" toggle110={toggleScrollable110}>Schwangerschaft und Geburt</ModalHeader>
    <ModalBody  class="bg-light justifyText">
      <div >
     <p>Die Zeit der Schwangerschaft und der Geburt galt als Phase besonderer Hilflosigkeit und Schutzbedürftigkeit. Ähnlich wie der Prozess des Sterbens, galt sie als Schwellenphase, in der übernatürliche Kräfte auf mannigfaltige Weise Einfluss auf Mutter und Kind nehmen konnten. Im Hinblick auf den Umgang des Menschen mit derartigen liminalen Phasen, ist diese Vorstellung zunächst nicht außergewöhnlich und entspricht ihrem Wesen nach dem menschlichen Wunsch, als besonders wahrgenommene und erlebte, mitunter auch auf Erfahrungswissen basierende Abschnitte des Lebens als solche zu kennzeichnen und durch bestimmte Handlungsformen eine Kontingenz zu bewältigen, die eben üblich, aber doch nicht alltäglich ist. </p>
     <p>Sowohl amtskirchliche Benediktionale oder pastorale Wegweisungen, wie auch der organisch gewachsene Volksglaube stellten mit Segens- oder Andachtsformen Mittel bereit, die im religiösen Kult erlaubt und akzeptiert waren. Volksfromme Ausdrucksweisen waren etwa die Anrufung des Heiligen Godehart von Niederaltaich oder der Heiligen Margarete von Antiochien, die die Gnade einer guten Geburt erwirken sollten. Kirchlich approbierte Segen für Mutter und Kind, die – ähnlich jedem anderen Segen – die Hilfe und den Beistand Gottes auf diese herabrufen sollten wurden in der gelebten Volksfrömmigkeit etwas manifestere Formen, wie etwa der Darbringung von Wachsopfern ergänzt. 
      <Image class="center" fluid alt="votivkröte" src="/images/Votivkroeten.jpg" />
      
    Im Rahmen der kirchlichen Lesart waren diese bis zu einem gewissen Grad geduldet oder sogar erwünscht, wohingegen weniger mit dem katholischen Glauben kongruentere Verfahrensweisen abgelehnt wurden. So stellten Votivgaben aus Wachs oder anderen Materialien, aus kirchlicher Sicht kaum Probleme dar, da sie als gesunder Ausdruck der Dankbarkeit und der Hingabe angesehen wurden, solange sie in ihrer Gestalt nicht Grund zum sittlichen Anstoß gaben.</p>
     <p>Kritischer wurden abergläubischen Erzählungen und Mythen gesehen, die sich rund um das Thema Schwangerschaft und Geburt drehten, denen mittels direkter kirchlicher Verbote aber kaum beizukommen war. Die Vorstellung, dass konkrete Handlungen direkten Einfluss auf das Kind im Leib der Mutter haben würden war weit verbreitet: Ein Spinnrad zu berühren oder unter einer Wäscheleine hindurchzugehen, barg ebenso wie das Tragen von Ringen oder Halsketten die Gefahr, die Nabelschnur würde sich bei der Geburt um den Kopf des Kindes wickeln.</p>
     <p>Gerade in existentiellen Lebenssituationen suchten die Menschen scheinbar vermehrt in äußeren Mitteln einen sicheren Weg, um den gewünschten Ausgang einer Situation (mit-) zu bestimmen. Unter anderem deshalb ist auch eine besonders dichte Überlieferung von Quellen, die über Geburt und Kindheit im volksfrommen und abergläubischen Kontext Aufschluss geben, feststellbar. Dies gilt auch für das Dingolfinger Umland. So liefert beispielsweise der Vilsbiburger Priester Bartholomäus Spirkner in seiner Abhandlung Aberglaube auf kirchlichem Gebiete eine Reihe von Beispielen abergläubischer Praktiken rund um das Thema Kindheit, in welchem „äußere Mittel“ eine tragende Rolle spielen. So zum Beispiel im Bericht von Spirkner über einen in Niederbayern und der Oberpfalz vorherrschenden Aberglauben um 1900 an die Schutzmacht von Amuletten:</p>
     <i>Die Hebamme schmückt und trägt das Kind zur Taufe, sorgsam mit einem Amulett oder sonst etwas Geweihten behängt. Falls ein altes Weib auf dem Weg grüßt, darf man nicht danken, damit, wenn es etwa eine Hexe wäre, sie keine Gewalt über das Kind habe.</i><br><br>
    <p>Die Annahme, Mutter und Kind wären insbesondere während des Zeitraums von Schwangerschaft bis zur Taufe bevorzugtes Angriffsziel für dämonische Mächte, findet sich in diesem Beispiel wieder. Die Vorstellung, dass Zauberer und Hexen leichteren Zugriff auf (ungetaufte) Kinder hätten, wurde zwar nie ernsthaft von katholischen Theologen thematisiert, doch blieb es bestimmendes Motiv im Alltagsleben der Gläubigen. Um eben diesen Zugriff abzuwehren, wurden unterschiedliche äußere Mittel wie das von Spirkner beschriebene Amulett angewandt. Deutlich wird an diesem Fall, dass die Schutzkraft als vom Objekt an sich ausgehen sollte, was aus kirchlicher Sicht einen „Tatbestand“ des Aberglaubens erfüllte. </p>
    <p>Akademische Theologie und volksfromme Interpretation standen durchaus in interdependenter Beziehung zueinander, was dazu beigetragen haben mag, dass die Phantasie der Gläubigen beflügelt wurde und diese eigene Vorstellungen über das System der Wirkung bestimmter Dinge oder Handlungen entwickelten. Wenn beispielsweise in der Katechese Aspekte des Taufsakramentes behandelt – und vielleicht nicht verstanden – wurden, konnte es vorkommen, dass diese in einen neuen Zusammenhang gebracht wurden. Amtskirchliche, meist liturgische Handlungen wurden von den Gläubigen individuell interpretiert, woraus eine neue Lesart entstand, in der ein oft nicht trennscharf feststellbarer Übergang zu magischen Vorstellungen erkennbar ist. Auch für einen solchen Fall liefert Spirkner ein Beispiel aus Niederbayern: </p>
    <i>Während der Taufhandlung darf der Priester kein Wort auslassen in den Gebetsformeln, die er spricht, denn ein solches Versehen hat unzweifelhaft zur Folge, dass das Kind, wenn es herangewachsen, zur Drud wird.</i> Sarkastisch fügt der Autor hinzu: <i>O weh den Gemeinden, die einen Seelsorger bekommen, der eine etwas Schwere Zunge hat.</i> <br> <br>
    <p>Zur Anwendung magischer Praktiken gehört der bestimmte Vollzug von Handlungen, Ritualen, Gebärden oder Sprüchen. Einen gewichtigen Faktor stellt bei letzterem die minutiös durchgeführte Aussprache und das vollständige Rezitieren der magischen Formel dar. Fehlt der Durchführende in dieser Hinsicht, wirkt der Zauber nicht oder nicht so, wie es beabsichtigt ist. Diesem Diktum folgend erklärt sich auch der ebenfalls von Spirkner festgestellte Aberglaube in Hinblick auf die Taufe eines Kindes. Das katholische Verständnis der Sakramente, die ex opere operato – also durch die vollzogene Handlung – wirken, stellt den Gegensatz zum magischen Verständnis dar. Wohingegen – aus katholischer Sicht – Magie versucht eine Deität in den Dienst der Menschen zu stellen und sich diese nutzbar zu machen, verhält es sich in Hinblick auf die Sakramente anders: Das Sakrament als Geschenk Gottes wird den Menschen zur Verfügung gestellt, was mit sich bringt, dass Versprecher oder andere kleine formale Fehler in der Regel nicht dazu führen, dass der Vollzug ungültig ist. Aus religionswissenschaftlicher Perspektive lassen sich freilich auch in liturgischen Praktiken des Katholizismus magische Aspekte ausmachen. Die Herauslösung dieser Elemente oder ein falsches Verständnis, vermengt mit dem Anspruch einer Verabsolutierung nach dem Grundsatz „Nur wenn, dann“, trugen auf dem Feld der Volksfrömmigkeit mitunter dazu bei, dass in der gelebten Alltagspraxis durch das Anerkennen und Anwenden eines Großteils der jeweiligen Gemeinschaft, Vorstellungen pseudodogmatisiert wurden. Dies konnte letztendlich zur Folge haben, dass nicht mehr der eigentliche Glaube als Fundament der vollzogenen Handlungen diente, sondern eben der magische Aspekt. Dies verdeutlicht die von Spirkner überlieferte Episode zur Kindstaufe.</p>
</div>
  </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggleScrollable110}>schließen</Button>
     
    </ModalFooter>
  </Modal>




<div class ="row g-0">
<Card class="col-sm-4 p-2">
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="111" src="/images/111.1.jpg" />
      </div>
      <h3 style="color: black">Gebete und andere Hilfsmittel <br>für Mutter und Kind</h3>
    <div>

   
    <Button color="primary" on:click={toggle111}>lesen</Button>
  

  <Modal isOpen={open111} {toggle111} {size}>
    <ModalHeader class="bg-primary text-light justifyText"{toggle111}>Gebete und andere Hilfsmittel für Mutter und Kind</ModalHeader>
    <ModalBody class="bg-light justifyText">
      <Image class="center" fluid alt="Goldener Schlüssel" src="/images/111.1.jpg" />
      <Image class="center" fluid alt="Goldener Schlüssel" src="/images/111.2.jpg" />

      <div>
<br><p>Im katholischen Bayern sollte sich die Mutter vor allem durch den Besuch der Heiligen Messe und den Empfang der Sakramente geistlich vorbereiten. Sowohl kirchlich approbierte Segnungsformen, wie auch von der Geb&auml;renden selbst vorgetragene Gebete waren weit verbreitete Fr&ouml;mmigkeitspraktiken vor und w&auml;hrend der Geburt. Zu Beginn des 20. Jahrhunderts findet sich beispielsweise im Rituale Romanum die <em>Benedictio mulieris pregnantis, de cuius periculo dubitatur</em>, welche &ndash; unter leichten Ver&auml;nderungen &ndash; aus bereits im Mittelalter verwendeten Segnungsformeln &uuml;bernommen wurde. Insbesondere wird dabei die Mutterschaft Mariens thematisiert und eine Erhaltung der Mutter und ihrer Leibesfrucht erfleht.&nbsp;In vielen Gebetsb&uuml;chern sind eigens f&uuml;r die Geburt gedachte Gebete enthalten, welche ohne die Beihilfe eines Priesters verrichtet werden konnten. Das ausgestellte, 1871 in Passau gedruckte Gebetsb&uuml;chlein <em>Goldener Himmel-Schl&uuml;ssel</em> verzeichnet beispielsweise ein solches <em>Gebet eines geb&auml;rendes Weibes</em>, in dem es unter anderem hei&szlig;t:</p>



<p><em>Mein gekreuzigter Heiland, Christe Jesu! Weil ich arme S&uuml;nderin keine Zeit mehr vor mir wei&szlig; und st&uuml;ndlich auf meine bevorstehende, schmerzliche Geburt warte, so nehme ich meine Zuflucht zu deinem heil. Kreuze und bitte dich durch die grausamen Schmerzen, welche du daran gelitten hast, verleihe mir Geduld und St&auml;rke, die Schmerzen der Geburt zu &uuml;berstehen. Gedenke, o Jesu, wie sich deine ganze Natur entsetzte, als die Henker die L&ouml;cher in das Kreuz bohrten</em> [&hellip;] <em>also entsetzet sich auch meine schwache Natur vor den instehenden Geburtsschmerzen und vor der gro&szlig;en Gefahr</em> [&hellip;]</p>



<p>Zahlreiche Gebete f&uuml;r Schwangere und Geb&auml;rende thematisieren den Umstand, dass die Geburt mit gro&szlig;en Schmerzen verbunden ist. Diesem liegt &ndash; freilich neben dem Erfahrungswissen &ndash; die katholische Auffassung zu Grunde, dass einzig und allein die Gottesmutter ganz ohne Schmerzen niedergekommen w&auml;re.&nbsp;Eine Geburt <em>musste</em> nach dieser Lesart also schmerzhaft sein; gleichzeitig wurden im Gebetstext aber die Schmerzen der geb&auml;renden Frau aber mit den Schmerzen Christi am Kreuz in Beziehung gesetzt.</p>



<p>Sp&auml;testens seit dem Hohen Mittelalter wurden &uuml;bernat&uuml;rliche Gefahren w&auml;hrend des Geburtsvorganges vermutet und zu bek&auml;mpfen versucht. Diese Vorstellung entsprang weniger den theologischen &Uuml;berlegungen kurialer Gelehrter, als beispielsweise denen der Heiligen Hildegart von Bingen. Diese r&auml;t etwa im IV. Buch ihrer Physica der Mutter w&auml;hrend der Geburt, einen Japsis in der Hand zu halten, um b&ouml;se Geister von sich und dem Kind fernzuhalten, denn:</p>



<p><em>[&hellip;] die Zunge der alten Schlange z&uuml;ngelt nach der Feuchtigkeit des Kindes, das den Mutterleib verl&auml;sst, und stellt darum zu dieser Zeit dem Kinde wie der Mutter nach</em>.</p>



<p>Abgesehen von solcherlei Gefahren aber waren es die konkreten Geburtsschwierigkeiten, welchen durch volksfromme und abergl&auml;ubische Mittel beizukommen versucht wurde. Wohingegen Theologen hinsichtlich der Anrufung bestimmter Heiliger w&auml;hrend der Geburt nat&uuml;rlich keine Bedenken &auml;u&szlig;erten, war der verbreitete Brauch, bei der Niederkunft das Johannesevangelium&nbsp;vorzulesen, aus kirchlicher Sicht problematischer. Nur insofern die Worte des Evangeliums Trost spendeten, nicht aber weil durch dessen Rezitation eine bestimmte Wirkung erzielt werde, war es erlaubt bestimmte Perikopen daraus vorzutragen.&nbsp;Bei Geburtsschwierigkeiten r&auml;t die Heilige Hildegart erneut, sich auf die Anwendung bestimmter Steine zu verlassen. Bei einer schweren Geburt soll man die Lenden der Geb&auml;renden mit einem Quarzstein umkreisen, ihn dann vor die Vulva halten und dabei folgende Worte sprechen:</p>



<p><em>&Ouml;ffnet euch, Wege und Tor, in jener Erscheinung, in welcher Gottmensch Christus erschien und die Schl&ouml;sser der H&ouml;lle &ouml;ffnete: so gehe du, Kind, aus dieser Pforte hervor, ohne zu sterben und ohne deine Mutter zu t&ouml;ten!</em></p>



<p>Danach sollte der Stein in einen G&uuml;rtel gesteckt und die Frau damit umg&uuml;rtet werden.</p>
    </div>

    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle111}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-4 p-2">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="112" src="/images/112.jpg" />
      </div>

      <h3 style="color: black">Wahre Länge Mariens</h3>
    <div>

   
    <Button color="primary" on:click={toggle112}>lesen</Button>
  

  <Modal isOpen={open112} {toggle112} {size}>
    <ModalHeader class="bg-primary text-light" {toggle112}>„Absonderliche Gnaden“: Die Wahre Länge Mariens </ModalHeader>
    <ModalBody class="bg-light justifyText">
     <Image class="center" fluid alt="wahreLaengeMariens" src="/images/112.jpg" /> 
     
     
     
     <div class="centerText">
     
    Die Praktik die Gebärende mit einem Gürtel zu umgeben hielt sich aber weit über das Mittelalter hinaus. Insbesondere Objekte, wie die ausgestellten Wahren Längen Mariens wurden  der Mutter wie ein Gürtel um den Bauch gebunden, was eine reibungslose Geburt gewährleisten sollte. <Image class="center" fluid alt="votivkröte" src="/images/112.1.jpg" /> <Image class="center" fluid alt="votivkröte" src="/images/112.2.jpg" /> <br>Auch für Dingolfing ist aus der jüngsten Zeit ein Fall überliefert, der die Anwendung einer Wahren Länge Mariens bezeugt. Während der Recherche zu dieser Ausstellung erzählte eine Zeitzeugin aus Dingolfing, ihr Mann habe ihr eine solche Länge Mariens in den Kreissaal gebracht. 
    <p>&bdquo;Absonderliche Gnaden&ldquo; w&uuml;rden demjenigen zuteil, der die Wahre L&auml;nge wie ein Amulett bei sich trage:</p>



<p><em>Wann eine Manns- oder Weibsperson eine solch heilige L&auml;ng bey sich tragt oder in seiner Behausung hat, der wird absonderliche Gnaden von unserer lieben Frau zu erwarten haben [&hellip;] Man mu&szlig; aber auf das wenigste dieselbe alle heiligen Frauenfest mit Andacht bethen, welche aber nicht lesen k&ouml;nnen, sollen alle Frauenfest 63 Ave Maria bethen</em> <em>[&hellip;] </em></p>



<p>Hinsichtlich der Geburt vermerkt die ausgestellte L&auml;nge aber:</p>



<p><em>absonderlich aber sollen ihnen die schwangere Frauen lassen anempfohlen seyn, wanns eine Frau, so in den Kindsn&ouml;then ist, mit Andacht bethet, die wird absonderliche Hilf und Beystand von unser lieben Frauen zu gewarten haben</em>.</p>



<p>Sogenannte <em>Heilige L&auml;ngen</em> (auch <em>Heilige Ma&szlig;e</em> genannt) sind seit dem 6. Jahrhundert belegt. Die Auffassung, dass es m&ouml;glich sei, eine heilige Person durch eine exakte Kopie ihrer K&ouml;rperma&szlig;e gewisserma&szlig;en als Stellvertreter zu benutzen und dadurch ihrer Heilkraft anteilig zu werden, war insbesondere im Mittelalter weit verbreitet und hielt sich bis in die Neuzeit. Das Dogma der Leiblichen Aufnahme Mariens in den Himmel, verunm&ouml;glichte es freilich an marianischen Reliquien Ma&szlig; zu nehmen, weshalb in solchen F&auml;llen die Statuen an Wallfahrtsorten vermessen wurden. Es konnte auch vorkommen, dass anstelle der gesamten L&auml;nge oder Dicke eines Heiligen nur ein bestimmter K&ouml;rperteil auf Papier nachgebildet wurde, der f&uuml;r bestimmte Anliegen heilsam sein sollte.<br />
Die Katholische Kirche hat bereits fr&uuml;h bestimmt, dass Devotionalien wie die <em>Wahre L&auml;nge Mariens</em> als Ausdruck eines Aberglaubens anzusehen seien. Der Versuch die Herstellung und den Vertrieb solcher Andenken einzud&auml;mmen blieb allerdings weitegehend erfolglos. Vor allem die &uuml;berschw&auml;nglichen Verhei&szlig;ungen, die auf den <em>Wahren L&auml;ngen</em> zu finden sind, scheinen das Kirchenvolk beeindruckt und zur Beliebtheit der L&auml;ngen beigetragen zu haben. Laut der ausgestellten <em>Wahren L&auml;nge</em>, sei sie nicht nur f&uuml;r eine gute Geburt anwendbar, sondern sch&uuml;tze eine Empfehlung an sie au&szlig;erdem vor:</p>



<p>[&hellip;] <em>des Teufels Anlauf, vor Feuer und Wassernoth, vor Armut, S&uuml;nd und Schanden, vor Diebstahl, Kett und Banden</em> [&hellip;] <em>allen Ungl&uuml;ck, Eisen und Waffen, vor geistlichen und leiblichen Fall, vor Brand und Gef&auml;ngni&szlig;, vor Gift und allen b&ouml;sen Nachstellungen, vor Kugel und Pfeil, vor Zauberey und allen Schrecken, vor ungerechtem Urtheil, und Nachstellung der Feinden, vor falschen Zungen, Ehrabschneidung, und Afterreden, und all anderen Uebel</em> [&hellip;]</p>



<p>Am Objekten wie den <em>Wahren L&auml;ngen</em> zeichnet sich einmal mehr die von der Katholischen Kirche unerw&uuml;nschte Vorstellung vieler Gl&auml;ubiger ab, dass bestimmte Dinge, die eben nicht den Stellenwert von Reliquien besitzen, durch das alleinige Mitsichf&uuml;hren oder der Verrichtung einer exakt vorgeschriebenen Anzahl bestimmter Gebete, eine f&uuml;r sicher angenommene Wirkung entfalten w&uuml;rden. In der volksfrommen Wahrnehmung aber waren derartige Vorstellungen h&auml;ufig mit der Heiligenverehrung verbunden. Grunds&auml;tzlich wurde diese ja sogar von der Kirche anempfohlen und gef&ouml;rdert, doch konnte sie auf der Ebene des Volksglaubens &ndash; sei es durch &uuml;berbordende Fr&ouml;mmelei, durch liturgiewidrige Aus&uuml;bung aus Unkenntnis oder gar durch die Spekulation von Devotionalienh&auml;ndlern &ndash; eben abergl&auml;ubische Ausw&uuml;chse annehmen. Franz Walter schreibt in seiner Handreichung <em>Aberglaube und Seelsorge</em> dazu:</p>



<p><em>An die Heiligenverehrung rankt sich wie wildes Kraut manche sinnliche Vorstellung, welche die Verbindung mit dem Heiligen weniger durch die Kraft des Gebetes als durch &auml;u&szlig;ere Mittel herzustellen sucht</em>.</p>

    
    </div>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle112}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-4 gx-3 ">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="engelbilder" src="/images/113.jpg" />
      </div>
      <h3 style="color: black">Kinderschutz</h3>
    <div>

   
    <Button color="primary" on:click={toggle113}>lesen</Button>
  

  <Modal isOpen={open113} {toggle113} {size}>
    <ModalHeader class="bg-primary text-light" {toggle113}>Kinderschutz</ModalHeader>
    <ModalBody class="bg-light justifyText">
      <div class="centerText">
<p>Auf dem Gebiet der Volksfr&ouml;mmigkeit erfreute sich der Glaube an Schutzengel gro&szlig;er Beliebtheit und fand zahlreiche Auspr&auml;gungen. Der von Lutheranern und Calvinisten zumindest kritisch gesehene Glaube daran, dass Engel sich um die Belange der Menschen k&uuml;mmern w&uuml;rden, verst&auml;rkte sich in katholischen Regionen ab dem 16. Jahrhundert. Im 19. Jahrhundert hatte die Engelsfr&ouml;mmigkeit im Volksglauben einen neuen H&ouml;hepunkt erreicht. Zwar warnte die Kirche seit jeher davor, dem Engelsglauben zu viel Platz im pers&ouml;nlichen Fr&ouml;mmigkeitsleben einzur&auml;umen, da sie darin die Gefahr erkannte, die einfache Bev&ouml;lkerung w&uuml;rde die Geistwesen als Projektionsfl&auml;che f&uuml;r mancherlei abergl&auml;ubische Vorstellung nutzen und das theologisch komplexe Wesen der Engel w&uuml;rde nicht g&auml;nzlich verstanden werden, was zu einer falschen Verehrung und einer Verdr&auml;ngung des Dreifaltigen Gottes f&uuml;hren k&ouml;nnte. Nichtsdestotrotz vereinnahmte der gelebte Volksglaube das Engelsmotiv rasch. Sowohl im sakralen Raum, wie auch im Alltagsleben der Gl&auml;ubigen hielten in zunehmendem Ma&szlig;e verniedlichte Engelsdarstellungen Einzug. Diese hatten im Erscheinungsbild nicht mehr viel gemein, mit den m&auml;chtigen und furchteinfl&ouml;&szlig;enden Geistwesen aus der Heiligen Schrift.<br />
Im h&auml;uslichen Raum erfreuten sich Engelsdarstellungen gro&szlig;er Beliebtheit und im Hinblick auf die Schutzbed&uuml;rftigkeit von Babys und Kindern, war das Engelmotiv ein bestimmendes. So waren beispielsweise Bilder f&uuml;r das Kinderzimmer oder Andachtsbilchen, Ende des 19. und Anfang des 20. Jahrhunderts beliebte Geschenke f&uuml;r Kinder. Druckereien und Devotionalienh&auml;ndler konnten aus der Beliebtheit des Engelmotives nat&uuml;rlich Kapital schlagen. Tausendfach wurden Bildchen dieser Art hergestellt und vertrieben. 
<Image class="center" fluid alt="engelbilder" src="/images/113.jpg" />

Die&nbsp; Motive &auml;hneln sich dabei h&auml;ufig: Kinder werden in Gefahrensituationen, wie einer Reise, beim &Uuml;berqueren von Gleisen oder beim Sammeln von giftigen Pilzen von einem Engel besch&uuml;tzt. Die Vorstellung, ein im Schlaf l&auml;chelndes Kind spiele mit den Engeln, war weit verbreitet. Auch hier galt aus katholischer Perspektive aber wieder der Grundsatz, dass solange der Glaube an und die Verehrung von Engeln mit der Lehre vereinbar war, kein grundlegender Handlungsbedarf erkannt wurde.<br />
Handlungsbedarf erkannte die Amtskirche aber da, wo der Kinderschutz mit kirchlich nicht approbierten Mitteln versucht wurde. Besonders in Hinblick auf den richtigen Umgang mit Neugeborenen war das einfache Volk erfinderisch und empf&auml;nglich f&uuml;r allerhand Schutzma&szlig;nahmen, gegen die d&auml;monische Einwirkung. Eine in Altbayern, aber auch anderen Regionen verbreitete d&auml;monische Gestalt, war die Drud. Gottfried Lammert, der bereits 1869 in seinem Werk <em>Volksmedizin in Bayern</em> <em>und medizinischer Aberglaube</em>, eine umfangreiche Sammlung verschiedenartiger Br&auml;uche zusammenstellte, erw&auml;hnt den in Nieder- und Oberbayern vorherrschende Vorstellung, Druden w&uuml;rden Kindern in der Wiege die Brust wundsaugen. <br> <br><Image class="center" fluid alt="engelbilder" src="/images/113.3.jpg" /> <br>Zum&nbsp;Schutz des neugeborenen sollte man ein Gebetbuch oder ein geweihtes Skapulier unter sein Kopfkissen legen. Auch hier zeigt sich wieder eine magische Vorstellung &uuml;ber die Wirksamkeit der angewandten Mittel. Noch deutlicher wird diese, betrachtet man den Brauch an der Wiege ein spezielles Zeichen, den Drudenfu&szlig; anzubringen, was eine allem Anschein nach ein weit verbreitetes Mittel zum Schutz der Babys war, glaubt man der Feststellung Spirkners auf dem Lande in Altbayern w&uuml;rde man nicht leicht eine Bettlade ohne den Drudenfu&szlig; finden.<br />
<Image class="center" fluid alt="engelbilder" src="/images/113.4.jpg" /> 
Grundlegend gilt es festzuhalten, dass die &Uuml;berlieferungslage &ndash; insbesondere f&uuml;r die Stadt Dingolfing &ndash; es nicht zul&auml;sst eindeutige Aussagen dar&uuml;ber zu treffen, wie weit verbreitet die bisher dargestellten abergl&auml;ubischen Praktiken in Bezug auf Geburt und Kindheit waren. Einzelne F&auml;lle zu bestimmten Zeiten, k&ouml;nnen nicht als sicherer Beleg f&uuml;r das durchg&auml;ngige Vorhandensein eines kulturellen Ph&auml;nomens gelten, wobei aber die breite &Uuml;berlieferung von Gebetsb&uuml;chern f&uuml;r Kinder und andere auf kindliche Bed&uuml;rfnisse abgestimmte Ausdrucksmittel der Volksfr&ouml;mmigkeit Zeugen daf&uuml;r sind, dass eben dieser Bereich einen festen Platz im Alltagsleben der Menschen besa&szlig;.</p>

</div>




    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle113}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>




</div>

<style>



h3 {

		color: white;
	}

</style>







