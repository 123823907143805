<script>

  import { Image } from 'sveltestrap';

</script>


<div id="Home" class="bg-dark text-white  pt-5 pb-4">
	<h1>VOLKSGLAUBE <br> & <br>ABERGLAUBE </h1>
	<h2>Herzensgeheimnisse und<br> düstere Schatten</h2>
	<Image fluid alt="skull" src="/images/skull_web.png" />

	<h3> Sonderausstellung<br> Museum Dingolfing</h3> <br>

    <div class="video"><iframe width="560" height="315" src="https://www.youtube.com/embed/6l7Du9-aSHg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
	
</div>

<style>


   h1 {
      padding: 10px;
      font-weight: bold;
      font-size: 3rem;

    
   }

   h2 {

    font-size: 1.5rem;
   
   }


</style>