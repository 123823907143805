<script>

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Image
  } from 'sveltestrap';

  let size;

  let open310 = false;
  let fullscreen310 = false;;
  let openScrollable310 = false;
  

  let open311 = false;
  let fullscreen311 = false;

  let open312 = false;
  let fullscreen312 = false;



  let open313 = false;
  let fullscreen313 = false;

  // const toggle110 = () => {
  //   fullscreen110 = true;
  //   open110 = !open110;
  // };

  const toggleScrollable310 = () =>
  {openScrollable310 = !openScrollable310; 
    fullscreen310 = true
  };

  const toggle311 = () => {
    size = 'xl';
    open311 = !open311;
  };

  const toggle312 = () => {
    size = 'xl';
    open312 = !open312;
  };

  const toggle313 = () => {
    size = 'xl';
    open313 = !open313;
  };

 
</script> 







<div class ="row g-0">
<Card class="col-sm-6 p-2">
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="fraisenbrief" src="/images/311.jpg" />
      </div>
      <h3 style="color: black">Fraisenbrief</h3>
    <div>

   
    <Button color="primary" on:click={toggle311}>lesen</Button>
  

  <Modal isOpen={open311} {toggle311} {size}>
    <ModalHeader class="bg-primary text-light" {toggle311}>Fraisenbrief</ModalHeader>
    <ModalBody class="bg-light justifyText">
      
   <Image class="center" fluid alt="111" src="/images/311.jpg" />   

<p>Frais ist ein historischer Begriff f&uuml;r Epilepsie oder Krampfanf&auml;lle, die durch den Verzehr von Produkten, welche aus mit Mutterkornpilz befallenem Getreide hergestellt werden, verursacht werden k&ouml;nnen. Das volksreligi&ouml;se Brauchtum hat eine Vielzahl von Mitteln gegen die Fraisen aufgeboten. Unter diese z&auml;hlt auch der ausgestellte Fraisenbrief.<br /> <Image class="center" fluid alt="111" src="/images/311.jpg" />  <br>
Dieser macht den Leser gleichsam zum Heiler, was anhand der &bdquo;Ich-Botschaften&ldquo; deutlich wird. Es wird n&auml;mlich nicht beschrieben, wer genau den Brief lesen soll. Dar&uuml;ber hinaus ist der Brief sozusagen f&uuml;r unterschiedliche Personen anwendbar. Durch die Setzung N. (und nennent den Menschen bey dem Namen, wo das N. stehet &hellip;) konnte der Fraisenbrief &ndash; einmal gekauft &ndash; immer wieder benutzt werden.<br />
Generell wurden solche Heilmittel aus kirchlicher Sicht skeptisch gesehen. Im vorliegenden Fall d&uuml;rfte insbesondere eine Passage Grund zur Vermutung, es handle sich um Aberglauben, gegeben haben. Nach der Anrufung der verschiedenen Namen Gottes, den Wunden Christi usw. folgt:<br />
[&hellip;] <em>wende ich dirs N. alle Berg und tiefe Tahl und durch alle flie&szlig;ende Wasser ab, auf da&szlig; der Leib ruhen und rasten mag</em> [&hellip;]<br />
Die Parallelen zu den Anrufungen des in Raum 1 gezeigten &bdquo;Coronagebetes&ldquo; sind evident.<br />
Die Gebrauchsanleitung &ndash; welche am Ende gegeben wird &ndash; instruiert, dass der Brief dreimal &uuml;ber den von der Frais befallenen Menschen gelesen und der Brief danach dem kranken Menschen auf die Brust gelegt werden sollte. Danach sollten die beistehenden Menschen Gebete verrichten und darauf warten ob eine Besserung oder der Tod eintritt.</p>


    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle311}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>


<Card class="col-sm-6 p-2">
  
  <CardBody class="bg-light text-dark">
    <div>
      <Image fluid alt="schabmadonnen" src="/images/312.jpg" />
      </div>

      <h3 style="color: black">Heil und Heilung</h3>
    <div>

   
    <Button color="primary" on:click={toggle312}>lesen</Button>
  

  <Modal isOpen={open312} {toggle312} {size}>
    <ModalHeader class="bg-primary text-light"{toggle312}>Heil und Heilung</ModalHeader>
    <ModalBody class="bg-light justifyText">
  <p><em>Die katholische Moral sieht den Hokuspokus magischer Krankenheilung durchaus nicht als etwas harmloses an. Und es l&auml;uft dabei so manches mit unter, was als grober Versto&szlig; gegen die christliche Sittlichkeit zu betrachten ist. [&hellip;] Der Geistliche soll stets die Sache der Vernunft vertreten, die ja jederzeit auch mit den wahren Interessen der Religion im Einklang steht. Gegen Einf&auml;ltigkeit und Bl&ouml;dsinn, die dann und wann die Pflege am Krankenbett &uuml;bernehmen wollen, soll der Seelsorger mit Nachdruck seine Stimme erheben, schon deswegen, weil aus Vorkommnissen, die den Schein des Aberglaubens an sich tragen, gegen die Religion Kapital geschlagen wird</em>.</p>



<p>So beschreibt Franz Walter in seinem Handbuch <em>Seelsorge und Aberglaube</em> ein Themenfeld, das man wohl mit <em>Heil und Heilung</em> bezeichnen k&ouml;nnte. Der Wunsch nach k&ouml;rperlicher Gesundheit war zu allen Zeiten und in allen Kulturen gegenw&auml;rtig. Das Christentum stellt dabei freilich keine Ausnahme dar und insbesondere innerhalb der katholischen Volksfr&ouml;mmigkeit finden sich zahlreiche nicht nur &bdquo;geistliche Heilmittel&ldquo; sondern eben auch Mittel, die aus einer Verquickung von abergl&auml;ubischen Vorstellungen, &uuml;berlieferter Volksmedizin und der Anwendung von aus der Natur stammenden Materialien bestand. Man bediente sich also einerseits Objekte, wie sie in Raum 1 und 2 bereits thematisiert wurden, andererseits aber auch der auf das Bed&uuml;rfnis der k&ouml;rperlichen Gesundheit spezialisierter Mittel, wie sie im Folgenden dargestellt werden. Die Schnittmenge zwischen Volksmedizin, Volksfr&ouml;mmigkeit und Aberglaube stellt innerhalb des Themenkomplexes wohl das breiteste Feld dar. Zuweilen ist es schwer eine exakte Grenze zu ziehen, zumal aus historisch-volkskundlicher Perspektive beispielsweise Fragen nach der tats&auml;chlichen oder placebohaften Wirkung nicht behandelt werden k&ouml;nnen.</p>



<h3>Heilende Fl&uuml;ssigkeiten</h3>



<p>Die Anwendung von besonderen Fl&uuml;ssigkeiten f&uuml;r medizinische Zwecke l&auml;sst sich &uuml;ber einen langen Zeitraum nachweisen. Bereits in der Sp&auml;tantike wurde das &Ouml;l aus den Lampen, die an den Gr&auml;bern der M&auml;rtyrer in den Katakomben in Rom brannten, als heilbringend angesehen. Unter Papst Damasus I. (366&ndash;348) wurde versucht, das Pilgerwesen auch in finanzieller Hinsicht nutzbar zu machen und die Katakomben wurden ausgebaut, damit die Menschenstr&ouml;me bessere M&ouml;glichkeiten zur Besichtigung hatten. Die Pilger kamen aus aller Welt und nahmen das &Ouml;l aus den Lampen mit nach Hause, wo es unter anderem als heilsames Mittel angewandt wurde. In sp&auml;terer Zeit ebbte dieser Brauch wieder ab und das &Ouml;l, welches bestimmte Reliquien absonderten, wurde f&uuml;r dieselben Zwecke genutzt.<br />
Das sogenannte Walburgis-&Ouml;l gilt im bayerischen Raum seit vielen Jahrhunderten als eine der bekanntesten und wirkm&auml;chtigsten Fl&uuml;ssigkeiten. Dabei handelt es sich um Wasser, welches am Sarkophag der Heiligen Walburga in Eichst&auml;tt austritt. Die dort lebenden Nonnen sammeln das Wasser und f&uuml;llen es in kleine Gef&auml;&szlig;e ab. Laut dem Zeugnis einer Gro&szlig;zahl von Votivtafeln, soll es vielen Menschen wieder zur Gesundheit verholfen haben. Die volksfromme &Uuml;berlieferung spricht davon, dass das &Ouml;l (welches ja eigentlich Wasser ist) aus dem Brustbein der Heiligen &Auml;btissin Walburga str&ouml;mt. <Image class="center" fluid alt="111" src="/images/312.2.jpg" /> <br>   Im Gegensatz zu anderen Mitteln der Volksmedizin, verortet sich das Walburgis-&Ouml;l deutlich im Rahmen der katholischen Lehre. In &bdquo;Beipackzetteln&ldquo; wird darauf hingewiesen, dass Beichte und Kommunion zusammen mit einem guten Vorsatz Grundbedingungen seien, um das Mittel anzuwenden. Das &Ouml;l selbst tritt damit gleichsam etwas hinter die eigentlichen (geistlichen!) Heilmittel zur&uuml;ck.<br />
In der heutigen Zeit vielleicht noch bekannter als das Walburgis-&Ouml;l ist eine andere Fl&uuml;ssigkeit, der heilsame Wirkung zugeschrieben wird. Das Wasser vom Marienwallfahrtsort Lourdes erfreut sich n&auml;mlich auch heute noch gro&szlig;er Beliebtheit. Das Wasser aus dem Brunnen in Lourdes wird nicht nur vor Ort als Heilmittel anerkannt, sondern eben auch in Gef&auml;&szlig;e &ndash; oft in Gestalt der Gottesmutter von Lourdes &ndash; abgef&uuml;llt und als Souvenir mit nach Hause gebracht. In Lourdes selbst sollen sich viele Wunder in Verbindung mit dem Wasser ereignet haben, wobei die meisten mit Krankenheilungen in Verbindung gebracht werden. Die Katholische Kirche begegnet diesem Ph&auml;nomen mit n&uuml;chterner Skepsis: Von ungef&auml;hr 7000 gemeldeten Wunderheilungen erkennt sie 70 an. <Image class="center" fluid alt="111" src="/images/312.3.jpg" />   Ein letztes Beispiel, das in den Bereich der &bdquo;Heiligen Fl&uuml;ssigkeiten&ldquo; f&auml;llt, ist das sogenannte Ingatius-Wasser. Dabei handelt es sich um gew&ouml;hnliches Wasser, welches unter der Anrufung des Heiligen Igantius von Loyola geweiht wird. Auch diesem wird eine heilende Wirkung &ndash; insbesondere gegen die Pest &ndash; zugesprochen. Dass aus kirchlicher Sicht eine nicht zu untersch&auml;tzende Gefahr von Heilmitteln dieser Art ausgeht, die darin besteht, die Gl&auml;ubigen w&uuml;rden die Fl&uuml;ssigkeit an sich als wirkm&auml;chtig verstehen, verdeutlicht das ausgestellte Heftchen, das &uuml;ber das Ignatius-Wasser informiert und erkl&auml;rt:</p>


<Image class="center" fluid alt="111" src="/images/312.4.jpg" /> <br>
<p><em>Nat&uuml;rlich liegt die wunderbare Kraft nicht gerade in dem Wasser, sondern in der F&uuml;rbitte des hl. Ignatius: aber unsere Bitten werden, wie die Erfahrung lehrt, um so sicherer und schneller erh&ouml;rt, wenn wir uns dieses Wassers bedienen.</em></p>



<h3>Scheyrer Kreuze</h3>



<p>Das Kreuz &ndash; eines der &auml;ltesten Zeichen des Christentums &ndash; besitzt im Rahmen der Volksfr&ouml;mmigkeit viele Bedeutungskontexte. Freilich wird davon auch der (k&ouml;rperlich) heilbringende Aspekt immer wieder in den Mittelpunkt des Interesses ger&uuml;ckt. Wie beispielsweise das in Raum 1 vorgestellte Caravacakreuz besonders hilfreich gegen Unwetter sein sollte, wurde einem anderen &ndash; in seiner Gestalt sehr &auml;hnlichem &ndash; Kreuz verst&auml;rkt die eine Wirksamkeit gegen Krankheiten zugesprochen. Es handelt sich um das sogenannte Scheyrer Kreuz. Mitte des 12. Jahrhunderts hatte der Jerusalemer Patriarch einige Partikel des sogenannten Wahren Kreuzes Christ auf Reisen geschickt und einige davon konnte das Kloster Scheyern in seinen Besitz bringen. Die Partikel wurden in ein Reliquiar eingearbeitet, dessen unterer Teil Erde aus sieben Orten Pal&auml;stinas enthalten soll. Eine Andacht vor dem Kreuz in Scheyern, sollte die selben Gnadengaben vermitteln, wie eine Wallfahrt ins Heilige Land. Diese Annahme war zwar entgegen der kirchenrechtlichen Bestimmungen, tat der Beliebtheit des Kreuzes allerdings keinen Abbruch &ndash; im Gegenteil. Es existieren tausende Kopien in allen erdenklichen Gr&ouml;&szlig;en des Kreuzes, die als Amulette getragen wurden. Charakteristisch f&uuml;r die Form des Scheyernkreuzes ist der becher&auml;hnliche Fu&szlig;, der wohl von einer Steckt&uuml;lle herr&uuml;hrt. In der religi&ouml;sen Volksmedizin kam diesem eine besondere Bedeutung zu, da er als Trinkbeh&auml;lter f&uuml;r Segenswasser genutzt wurde.</p>
<Image class="center" fluid alt="111" src="/images/312.5.jpg" /> 


<h3>Schabmadonnen und Schluckbildchen</h3>



<p>Ebenso wie der Brauch, bestimmte Fl&uuml;ssigkeiten als Heilmittel zu nutzen, ist auch die Praktik Staub, Partikel oder andere feste Stoffe zu medizinischen Zwecken zu sich zu nehmen, sp&auml;testens seit dem Mittelalter belegt. Im sp&auml;ten Mittelalter konnte es vorkommen, dass diese Praktik noch von kirchlicher Seite geahndet wurde, da dabei eine N&auml;he zur Hexerei vermutet wurde. Der Umgang mit solchen Formen volksfrommer Medizin, war aber seit jeher ambivalent. Bischof Gregor von Tours berichtet beispielsweise voller Verz&uuml;ckung &uuml;ber die Wirkung von Staub, welcher dadurch gewonnen wird, am Grab des Heiligen Martin zu kratzen: Alle &auml;rztlichen Arzneien w&uuml;rde dieses Mittel in den Schatten stellen. Nicht nur der Leib gesunde durch die Einnahme dieses Staubes, sondern auch die Seele.<br />
Bald fand eine Abwandlung der Praktik statt, bestimmte im Rahmen von Wallfahrten aufgesuchte Gr&auml;ber oder verehrte Statuten abzukratzen, die darin bestand, Miniaturen eben dieser anzufertigen, welche dann verkauft wurden. Diese sogenannten Schabfiguren konnten also mit nach Hause genommen werden und bei Bedarf abgeschabt. Die gezeigten Tonfiguren sind Miniaturen von Madonnenstatuen verschiedener Wallfahrtorte. Waren Mensch oder Tier erkrankt, schabte man kleine Mengen davon ab und mischte sie unter Essen oder Futter. <Image class="center" fluid alt="111" src="/images/312.jpg" /> Auch war es &uuml;blich kleine Sp&auml;ne von h&ouml;lzernen Schabmadonnen abzul&ouml;sen und daraus einen Sud zu kochen, der als Heilmittel gegen Krankheiten galt.<br />
Andere Mittel die zum Verzehr gedacht waren, sind sogenannte Schluckbildchen. Vom 18. bis zum 20. Jahrhundert wurden diese an Wallfahrtsorten oder von umherziehenden H&auml;ndlern preisg&uuml;nstig verkauft. Bei Krankheit sollten sie aus den vorgefertigten B&ouml;gen gel&ouml;st und hinuntergeschluckt werden. Diese Praktik war nicht nur in Bayern bekannt, sondern ist in ganz Europa nachzuweisen. Wahrscheinlich auch deshalb, da die lokale N&auml;he gegeben war, wurde das Heilige Offizium darauf aufmerksam und zu Beginn des 20. Jahrhunderts begannen sich die kirchlichen Gelehrten mit dem Ph&auml;nomen zu besch&auml;ftigen. <br><Image class="center" fluid alt="111" src="/images/312.6.jpg" /> <br>F&uuml;r den Fall der Schluckbilder liegt eine Quelle vor, welche explizit den Zusammenhang zwischen Volksmedizin und Aberglaube aus kirchlicher Perspektive behandelt. 1903 verlautbarte das Heilige Offizium in Rom n&auml;mlich, dass &bdquo;sofern Aberglaube oder die Gefahr des Aberglaubens ausgeschlossen sei&ldquo; die medizinische Nutzung von Schluckbildchen bedenkenlos sei. Eine n&auml;here Erl&auml;uterung, wann genau die Gefahr des Aberglaubens best&uuml;nde wird zwar nicht gegeben, allerdings darf man annehmen, dass &ndash; &auml;hnlich der Instruktion zum Ingatius-Wasser &ndash; darauf vertraut wurde, die Gl&auml;ubigen w&uuml;rden nicht im Mittel selbst, sondern in der Vermittlung durch die Heiligen und letztendlich im Wirken Gottes die Wirksamkeit annehmen.</p>



<h3>Gebetsheft: <em>Die heiligen sieben Himmelsriegel</em></h3>



<p>Abgesehen von Mitteln, die direkt eingenommen wurden, existieren kirchlich nicht approbierte Gebetsb&uuml;chlein, die au&szlig;er den darin enthaltenen Gebeten, allein durch ihr Vorhandensein gegen Krankheit sch&uuml;tzen sollten. Ein Beispiel daf&uuml;r ist das ausgestellte Heftchen <em>Die heiligen sieben Himmelsriege</em>l. Es beginnt mit einer einleitenden Erz&auml;hlung, die den Leser unterrichtet, dass die darin enthaltenen Gebete einem frommen Einsiedler von einem Engel mitgeteilt worden w&auml;ren. Allein dadurch soll der Text an Autorit&auml;t gewinnen. Die Gebete sind sehr kurz, allerdings folgt der Inhalt des Buches den bekannten &uuml;berschw&auml;nglichen Versprechungen, bei richtiger Anwendung. Das alleinige Auflegen des Heftchens bewahre beispielsweise vor Besessenheit und geb&auml;rende Frauen vor einer Todgeburt. Sollte das Heftchen in einem Haus aufbewahrt werden, w&uuml;rden die Bewohner vor Unwetter und Krankheit besch&uuml;tzt werden. Auch die Todesstunde sollte durch das Beisichf&uuml;hren des Buches offenbar werden. Der Druck des Buches wurde von der Kirche verboten.
<br><br /> <Image class="center" fluid alt="111" src="/images/312.7.jpg" /> <Image class="center" fluid alt="111" src="/images/312.8.jpg" /> 
<h3>Zachariassegen</h3>

Ein weiteres von der Kirche verbotenes Buch, wurde bereits in Raum 2 behandelt. Abgesehen davon aber, dass der <em>Geistliche Schild</em> als Schutzmittel genutzt wurde, finden sich in ihm weitere von der Kirche zumindest skeptisch gesehene Gebets- und Segensformeln. Die bekannteste ist der sogenannte Zachariassegen. Zum ersten Mal erscheint die Buchstabenabfolge, deren genaue Bedeutung nur wenigen Menschen bekannt war, im von der Kirche verbotenen Buch <em>Enchiridion Leonis Papae </em>(1525). Mittels einer ins Leben gerufenen Legende wurde versucht dem Segen weitere Berechtigung zu verschaffen.
 <Image class="center" fluid alt="111" src="/images/312.9.jpg" />  <br> Der Vortext zum eigentlichen Segen lautet im Geistlichen Schild folgenderma&szlig;en:

<p><em>Buchstaben gegen die Pest zu tragen</em><br />
<em>Es bezeuget Herr Franciscus Solarius, Bischof zu Salamanca, da&szlig; da&szlig; im Concilio zu Trient, Anno 1546, &uuml;ber zwanzig Bisch&ouml;ffe und Ordens-Generalen an der Pest gestorben, da habe der Patriarch zu Antiochia allen gerathen, folgende Buchstaben, so von dem H. Zacharia, Bischoffen von Jerusalem, mit ihrer Auslegung und Beschw&ouml;rung hinterlassen worden, als ein gewisses Mittel gegen die Pest bey sich zu tragen. Und als di&szlig; geschehen da ist kein einiger mehr an der Pest gestorben. Und wann man dieselbiegen Buchstaben &uuml;ber eine Th&uuml;r geschrieben, so seynd alle in seynem Haus wohnende f&uuml;r der Pest bewahret worden. </em><br />
<em>ZDIABIZSABZHGPBFRS</em></p>



<p>Der Segen wurde als magische Formel gedeutet, deren Rezitation als Schutz gegen die Pest galt. Obwohl von Papst Gregor XIII. (1572&ndash;1585) best&auml;tigt, wurden im 17. Jahrhundert theologische Stimmen laut, die im Zachariassegen den Ausdruck eines Aberglaubens erkannten.</p>



    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={toggle312}>schließen</Button>
     
    </ModalFooter>
  </Modal>
</div>
  </CardBody>
  
</Card>







</div>

<style>



h3 {

		color: black;
	}

</style>







