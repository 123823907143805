<script>
  import {Container, Image } from 'sveltestrap';
 
</script>

<div id="intro">
<div  fluid class="bg-dark" mt-4 pt-4>


<div class="video">
   <iframe width="560" height="315" src="https://www.youtube.com/embed/uR3-i3ZKvFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</div>
<br>
 </div>

<Image style="width:100%" fluid alt="fg1" src="/images/fg1.jpg"  />
</div>


