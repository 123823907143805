<script>
import { Container, Fade, Card, Button } from 'sveltestrap';

let isOpen = false;
</script>

    
<div id ="Raum1"> 
<Container fluid class="bg-dark text-white fluid p-3">

    <h2>Von der Wiege bis zur Bahre – Durchdringung von Raum und Zeit</h2>
<p style="text-align: justify">Volksfrömmigkeit und Aberglaube durchdringen Raum und Zeit. Mögen ihre Ausdrucksformen auch regionalen oder temporären Einflüssen unterworfen sein, so gibt es doch Konstanten, an denen sie sich immer wieder manifestieren. Situationsentscheidende Momente, wie Geburt oder Tod, das alltägliche Leben bestimmende Orte, wie Haus und Hof oder das seit jeher dagewesene Streben des Menschen nach Glück und Reichtum stellen dabei Bezugsgrößen dar, an denen anschaulich verdeutlicht werden kann, wie intensiv volksfromme und abergläubische Dinge, Handlungen und Vorstellungen Form angenommen haben .</p>



<div class="video">
<iframe width="560" height="315" src="https://www.youtube.com/embed/ASSaDdR5zKg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 


</Container>




</div>



