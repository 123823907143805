<script>
import { Container, Image } from 'sveltestrap';


</script>

    
<div id ="Raum3"> 
    <Image style="width:100%" fluid alt="fg2" src="/images/fg2.jpg"  />
<Container fluid class="bg-dark text-white fluid p-3">
<h1>Heil und Heilung</h1>
<p><em>„Die katholische Moral sieht den Hokuspokus magischer Krankenheilung durchaus nicht als etwas harmloses an. Und es läuft dabei so manches mit unter, was als grober Verstoß gegen die christliche Sittlichkeit zu betrachten ist. […] Der Geistliche soll stets die Sache der Vernunft vertreten, die ja jederzeit auch mit den wahren Interessen der Religion im Einklang steht. Gegen Einfältigkeit und Blödsinn, die dann und wann die Pflege am Krankenbett übernehmen wollen, soll der Seelsorger mit Nachdruck seine Stimme erheben.“</em> <br> - Franz Walter, Seelsorge und Aberglaube.</p>

<div class="video"><iframe width="560" height="315" src="https://www.youtube.com/embed/SJ6u8LRHfDA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
    


</Container>




</div>